import * as Sentry from '@sentry/sveltekit';
import { get } from 'svelte/store';
import {
	ABORT_REASON_POLLER_RESTART,
	ABORT_REASON_POLLER_STOP
} from '@variational/common-ui/constants';

import { PUBLIC_ENVIRONMENT, PUBLIC_RELEASE_ID, PUBLIC_SENTRY_DSN } from '$env/static/public';
import { banner } from '$lib/stores/banner';
import { sampleRate } from '$lib/utils/sentry';

Sentry.init({
	environment: PUBLIC_ENVIRONMENT,
	dsn: PUBLIC_SENTRY_DSN,
	tracesSampleRate: sampleRate,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: sampleRate,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: sampleRate,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		Sentry.replayIntegration(),
		Sentry.browserTracingIntegration(),
		Sentry.httpClientIntegration(),
		Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] })
	],
	tracePropagationTargets: [window.location.host],
	release: PUBLIC_RELEASE_ID || `omni-${PUBLIC_ENVIRONMENT}`,
	allowUrls: [window.location.host],
	beforeSend(event, hint) {
		const exception = event.exception?.values?.[0];
		if (
			exception &&
			((exception.type === 'TypeError' && exception.value === 'Failed to fetch') ||
				exception.type === 'RateLimitError' ||
				exception.value === ABORT_REASON_POLLER_RESTART ||
				exception.value === ABORT_REASON_POLLER_STOP)
		) {
			// Drop TypeError: Failed to fetch. This is thrown when a pending request is cancelled by navigating away.
			// Drop RateLimitError
			// Drop AbortError for poller
			return null;
		}

		// ignore these console error
		if (
			event.message?.match(/walletconnect.org/i) ||
			event.message?.match(/Error loading image/i)
		) {
			return null;
		}

		const error = hint.originalException as Error;
		const $banner = get(banner);
		if ($banner.show && $banner.isMaintenance) {
			// Ignore 500 error during maintenance
			if (error?.message?.match(/50[\d]/i)) {
				return null;
			}
		}
		return event;
	}
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
